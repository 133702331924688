<template>
	<div class="circular-progress">
		<svg width="110" height="110" viewBox="-5 0 110 100" style="transform: rotate(-90deg)">
			<circle class="circle-chart__background" stroke="#fff" stroke-opacity="0.3" stroke-width="10" fill="none" cx="50" cy="50" r="50"></circle>
			<circle ref="circle" class="circle-chart__segment" cx="50" cy="50" r="50" stroke-linecap="round" stroke-dasharray=" 160" stroke="#ff7300" stroke-width="10" fill="none" stroke-dashoffset="0"></circle>
		</svg>
		<div class="circular-progress-value">{{ progress }}</div>
	</div>
</template>

<script>
export default {
	name: 'CircularProgressGraph',
	props: {
		value: String,
		color: {
			type: String,
			default: '#ff7300',
		},
	},
	data: () => ({
		progress: '0%',
	}),
	mounted() {
		this.$nextTick(() => {
			const percent = parseInt(this.value);
			let counter = 0;
			const intervalId = setInterval(() => {
				if (counter == percent) {
					clearInterval(this.intervalId);
				} else {
					counter += 1;
					this.progress = counter + '%';
				}
			}, 30);

			const outline = this.$refs.circle;
			outline.style.stroke = this.color;
			const outlineLength = outline.getTotalLength();
			outline.style.strokeDasharray = outlineLength;
			// 초기 strokeDashoffset 값을 outlineLength로 설정하여 애니메이션 시작점을 설정
			outline.style.strokeDashoffset = 0;

			// 애니메이션 지속 시간을 this.value 비례하도록 설정
			let duration = 1000; // 예: this.value 100일 경우, 애니메이션은 1000ms 동안 진행됩니다.
			let elapsed = 0;
			function animate() {
				elapsed += 5; // 10ms 마다 elapsed를 증가
				if (elapsed <= duration) {
					let currentOffset = outlineLength * (1 - elapsed / duration);
					if (currentOffset >= outlineLength * (1 - percent / 100)) {
						outline.style.strokeDashoffset = currentOffset;
					}
					requestAnimationFrame(animate); // 다음 애니메이션 프레임을 요청
				} else {
					// 애니메이션이 끝났을 때 strokeDashoffset를 최종 값으로 설정
					outline.style.strokeDashoffset = outlineLength * (1 - percent / 100);
				}
			}
			animate();
		});
	},
};
</script>
<style scoped lang="scss">
.circular-progress {
	display: flex;
	position: relative;
	height: 138px;
	width: 138px;
	align-items: center;
	justify-content: center;
	&-value {
		display: flex;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 10;
		width: 68px;
		height: 68px;
		padding: 10px;
		border-radius: 50%;
		background-color: rgba(255, 255, 255, 0.6);
		font-size: 24px;
		font-weight: 600;
		transform: translate(-50%, -50%);
		color: #424242;
		align-items: center;
		justify-content: center;
	}
}
</style>
