<template>
	<div ref="typing" class="typing"></div>
</template>

<script>
export default {
	name: 'TextTyping',
	props: {
		content: String,
	},
	data: () => ({
		count: 0,
	}),
	mounted() {
		this.$nextTick(() => {
			const timerId = setInterval(() => {
				if (this.content.substring(this.count, this.count + 4) === '<br>') {
					this.$refs.typing.innerHTML += '<br />';
					this.count += 4; // '<br />'의 길이만큼 count 증가
				} else {
					this.$refs.typing.innerHTML += this.content[this.count];
					this.count++;
				}
				if (this.count >= this.content.length) {
					this.count = 0;
					clearInterval(timerId);
				}
			}, 50);
		});
	},
};
</script>
